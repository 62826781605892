.thankyou-div {
  margin: 30px;
  font-size: 24px;
  text-align: center;
}

.thankyou-content-div {
  width: 100%;
}

.thankyou-img {
  width: 25%;
  margin: 10px;
  display: block;
  margin: auto;
}

.thankyou-txt {
  margin: 15px 0px 0px 0px ;
  font-size: 18px;
}

@media only screen and (min-width: 800px) {
  .thankyou-img {
    margin: 10px;
    width: 25%;
    float: left;
  }
}

@media only screen and (min-width: 800px) {
  .thankyou-txt {
    width: 75%;
    margin: 25px 0px 0px 0px ;
    font-size: 18px;
  }
}
 