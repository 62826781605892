.ReplayDetails {
  margin: 10px;
}

.replay-details-table {
  width: 100%;
}

.replay-details-header {
  background: lightgrey;
}

.replay-details-id {
  font-size: 10px;
}

.replay-details-title {
  font-size: 12px;
  width: 100%;
  font-weight: 500;
  padding-left: 20px;
}

.replay-details-header {
  padding: 5px;
}

.replay-details-teams-div {
  display: flex;
  text-align: center;
  padding-top: 20px;
}

.replay-details-faction-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.replay-details-faction-div:hover {
  cursor: pointer;
}

.replay-details-faction-icon {
  width: 25px;
  height: 25px;
  margin: auto;
}

.replay-details-player-name {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.replay-details-uploader-td {
  width: 33%;
}

.replay-details-uploader-div {
  text-align: right;
  margin: 5px;
}

.replay-details-txt {
  font-size: 13px;
}

.replay-details-name {
  font-size: 12px;
  font-weight: 500;
}

.replay-details-uploader-txt {
  font-size: 9px;
}

.replay-details-uploader-name {
  font-size: 10px;
  font-weight: 500;
}

.replay-details-map-div {
  align-self: center;
  margin-left: 10px;
}

.replay-details-map-name {
  font-size: 14px;
}

.replay-details-patch-name {
  font-size: 12px;
  font-weight: 300;
  margin-top: 5px;
  padding-right: 3px;
  padding-left: 3px;
  background-color: lightgrey;
  text-align: center;
}

.replay-details-mappack-name {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.replay-details-minimap {
  width: 75px;
  height: 75px;
  margin: 5px;
  margin-left: 15px;
}

.replay-details-minimap-rev {
  width: 75px;
  height: 75px;
  margin: 5px;
  margin-left: 15px;

  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.replay-details-team {
  width: 50%;
  background-color: #EEEEEE;
  margin: 3px;
  padding: 5px;
}

.replay-details-avatar {
  height: 25px;
  width: 25px;
}

.replay-details-action-div {
 display: flex;
 justify-content: right;
}

.replay-details-action-btn {
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  min-width: 120px;
  text-align: center;
}

.match-results-txt {
  font-size: 14px;  
  text-align: center;
  margin-right: 5px;
  font-weight: 500;
}

.match-result-item {
  display: block;  
  text-align: center;
  padding: 5px;
  margin: 2px;
  background: lightgray;
}

.match-result-winner {
  margin-left: 3px;
  color: green;
}

.match-result-loser {
  margin-left: 3px;
  color: red;
}

.match-result-na {
  margin-left: 3px;
  color: grey;
}