.stats-sheet-loading-div {
    margin-top: 100px;
    text-align: center;
    width: 100%;
}

.stats-sheet-loading-text {
    display: block;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
}