.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.app-bg {
  /*
    height: 100%; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    background-image: url('./res/game_bg.png');
    color: white;
  */
}

.app-title {
  font-size: 26px;
}

.app-title-small {
  margin-left: 8px;
  font-size: 10px;
}

.body {
  overflow:visible;
}

div {
  font-family: 'Rubik', sans-serif;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.text-small {
  font-size: 11px;
}

.err-text {
  font-size: 12px;
  color: red;
}

.err-text-large {
  font-size: 18px;
  color: red;
}

.ul {
  list-style-type:none;
}

.text-pre {
  white-space: pre-wrap;
}

.avatar-large {
  width: 100px;
  height: 100px;
}

.avatar-med {
  width: 30px;
  height: 30px;
}

.avatar-small {
  width: 20px;
  height: 20px;
}

.circle-small {
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  font-size: 10px;
  background: red;
  color: white;
  text-align: center;
  padding-top: 2.5px;
}

.display-table {
  display: table;
}

.display-table-cell {
  display: table-cell;
}

.grid-container {
  display: inline-grid;
}

.input {
  min-height: 37px;
  padding: 5px;
  border-radius: 3px 3px;
}

.wt-700 {
  font-weight: 700;
}

.wt-500 {
  font-weight: 500;
}

.flex-container {
  display: flex;
}

.flex-1 {
  flex-grow: 1;
}

.flex-2 {
  flex-grow: 2;
}

.flex-3 {
  flex-grow: 3;
}

.flex-4 {
  flex-grow: 4;
}

.float {
  display: inline-block;
}

.collapsed {
  display: none;
}

.tooltip1 {
  margin-left: 3px;
  font-size: 14px;
  border-bottom: 2px dotted black;
  display: inline-block;
}

.tooltip1:hover {
  cursor: pointer;
}