.file-details-card {
    min-width: 500px;
}

@media (max-width: 768px) {
    .file-details-card {
        min-width: 250px;
    }
}

.div-test-ver {
    display: inline-block;
    padding: 2px 8px 2px 8px;
    color: white;
    background-color: orange;
    border: 1px solid orange;
    border-radius: 4px;
}

.div-latest-ver {
    display: inline-block;
    padding: 2px 8px 2px 8px;
    color: white;
    background-color: green;
    border: 1px solid green;
    border-radius: 4px;
}

.div-file-desc {
    margin-top: 15px;
    white-space: pre-line;    
    font-size: 14px;
}

.div-file-subtitle {
    font-size: 13px;
}

.div-file-date {
    background-color: gainsboro;
    border-radius: 5px;
    display: inline-block;
    padding: 2px 5px 2px 5px;
    font-size: 12px;
}

.text-ver-name {
    font-size: 20px;
    text-decoration: underline;
}