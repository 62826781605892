.error-div {
  margin: 30px;
  font-size: 16px;
  text-align: center;
}

.error-img {
  width: 40%;
  object-fit: contain;
  margin: 20px;
}