.TournamentDetails {
    position:relative;
    z-index: 1;
    min-height: 1000px;
    overflow-x: hidden;
}

.div-tournament-id {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    background-color: #999;
}

.txt-tournament-id {
    text-align: center;
    text-decoration: underline;
}

.div-tournament-title {
    background-color: black;
}

.title-tournament {
    text-align: center;
    font-size: 24px;
    color: white;
    display: block;
}

.summary-tournament {
    text-align: center;
    font-size: 14px;
    color: white;
    display: block;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
.background-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.div-brackets-container {
    display: block;
    overflow-x: auto;
}

.div-title-row {
    position: relative;
}

.tournament-btns {
    display: flex;
    justify-content: center;
}

.btn-checkin {
    margin: 5px;
}

.btn-signup {
    margin: 5px;
}

.div-players-list {
    background-color: black;
    padding: 5px;
    overflow-x: auto;
    max-width: 100%;
}

.div-players-header {
    color: lightgray;
    text-decoration: underline;
}

.div-players-body {
    font-size: 13px;
}

.icon-player {
    width: 12px;
}

.div-players-body > div:nth-child(even) {
    background-color: rgb(43, 43, 43); 
}

.div-tournament-date {
    display: inline-block;
    text-align: center;
    margin: 5px;
    color: white;
    border-radius: 5px;
    background-color: darkgray;
    padding: 5px;    
}

.txt-tournament-date {
    font-size: 18px;
}

.div-tournament-countdown {
    text-align: center;
    margin: 5px;
    color: white;
    border-radius: 5px;
    background-color: darkgray;
    display: inline-block;
    padding: 5px;    
}

.txt-tournament-countdown {
    font-size: 24px;
    min-width: 200px;
}

.div-main-rows {
    margin-top: 70px;
    position: relative;
}

.div-round-title {
    display: block;
    width: 150px;
    height: 60px;
}

.div-round-title-name {
    display: block;
    color: white;
    border-color: grey;
    border-width: thin;
    border-style: solid;
    background-color: darkblue;
    text-align: center;
    height: 30px;
    width: 150px;
    line-height: 30px;
}

.div-round-title-desc-container {
    display: block;
}

.div-round-title-bo {
    text-align: center;
    height: 20px;
    width: 150px;
    font-weight: 500;
    line-height: 20px;
}

.div-round-title-maps {
    text-align: center;
    height: 30px;
    width: 150px;
    line-height: 15px;
}

.div-team {
    display: block;
    text-align: center;
    background: lightblue;
    border-color: grey;
    border-width: thin;
    border-style: solid;
    height: 30px;
    width: 120px;
    line-height: 30px;
    padding: 0px 2px 0px 2px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.div-team-score {
    display: block;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .txt-tournament-countdown  {
        font-size: 12px;
        min-width: 110px;
    }

    .txt-tournament-date {
        font-size: 11px;
    }

    .div-players-body {
        font-size: 11px;
    }

    .div-players-header {
        font-size: 11px;
    }
    
}