body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.app-bg {
  /*
    height: 100%; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    background-image: url('./res/game_bg.png');
    color: white;
  */
}

.app-title {
  font-size: 26px;
}

.app-title-small {
  margin-left: 8px;
  font-size: 10px;
}

.body {
  overflow:visible;
}

div {
  font-family: 'Rubik', sans-serif;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.text-small {
  font-size: 11px;
}

.err-text {
  font-size: 12px;
  color: red;
}

.err-text-large {
  font-size: 18px;
  color: red;
}

.ul {
  list-style-type:none;
}

.text-pre {
  white-space: pre-wrap;
}

.avatar-large {
  width: 100px;
  height: 100px;
}

.avatar-med {
  width: 30px;
  height: 30px;
}

.avatar-small {
  width: 20px;
  height: 20px;
}

.circle-small {
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  font-size: 10px;
  background: red;
  color: white;
  text-align: center;
  padding-top: 2.5px;
}

.display-table {
  display: table;
}

.display-table-cell {
  display: table-cell;
}

.grid-container {
  display: inline-grid;
}

.input {
  min-height: 37px;
  padding: 5px;
  border-radius: 3px 3px;
}

.wt-700 {
  font-weight: 700;
}

.wt-500 {
  font-weight: 500;
}

.flex-container {
  display: flex;
}

.flex-1 {
  flex-grow: 1;
}

.flex-2 {
  flex-grow: 2;
}

.flex-3 {
  flex-grow: 3;
}

.flex-4 {
  flex-grow: 4;
}

.float {
  display: inline-block;
}

.collapsed {
  display: none;
}

.tooltip1 {
  margin-left: 3px;
  font-size: 14px;
  border-bottom: 2px dotted black;
  display: inline-block;
}

.tooltip1:hover {
  cursor: pointer;
}
.file-details-main {
  margin: 10px;
  padding: 10px;
  min-height: 175px;
  background-color: #d8e4ed;
}

.file-details-name {
  font-size: 18px;
  margin-bottom: 3px;

}

.file-details-banner {
  width: 150px;
}

.file-type-title {
  font-size: 16px;
}
.file-details-card {
    min-width: 500px;
}

@media (max-width: 768px) {
    .file-details-card {
        min-width: 250px;
    }
}

.div-test-ver {
    display: inline-block;
    padding: 2px 8px 2px 8px;
    color: white;
    background-color: orange;
    border: 1px solid orange;
    border-radius: 4px;
}

.div-latest-ver {
    display: inline-block;
    padding: 2px 8px 2px 8px;
    color: white;
    background-color: green;
    border: 1px solid green;
    border-radius: 4px;
}

.div-file-desc {
    margin-top: 15px;
    white-space: pre-line;    
    font-size: 14px;
}

.div-file-subtitle {
    font-size: 13px;
}

.div-file-date {
    background-color: gainsboro;
    border-radius: 5px;
    display: inline-block;
    padding: 2px 5px 2px 5px;
    font-size: 12px;
}

.text-ver-name {
    font-size: 20px;
    text-decoration: underline;
}
/*
.custom-btn {
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

@media (max-width: 768px) {
    .custom-btn {
        padding: 5px 10px;
        font-size: 9px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
      }
}

.custom-btn > img {
    width: 15px;
}
*/
  
.custom-btn {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    grid-gap: 8px;
    gap: 8px; /* Add spacing between icon and text */
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    text-align: center; /* Ensure proper text alignment */
}

.custom-btn > img {
    width: 15px;
    margin-right: 5px;
}

.custom-btn > span {
    white-space: nowrap; /* Prevent text wrapping */
}

/* Styles for smaller screens */
@media (max-width: 768px) {
    .custom-btn {
        flex-direction: column; /* Stack items vertically */
        grid-gap: 4px;
        gap: 4px; /* Reduce gap between icon and text */
        font-size: 10px; /* Adjust font size */
        padding: 8px; /* Adjust padding for smaller buttons */
    }

    .custom-btn > img {
        margin-right: 5px;
    }
}

.custom-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.6), transparent);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0.1);
            transform: translate(-50%, -50%) scale(0.1);
}

.custom-btn:hover::before {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}

.custom-btn:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); /* Slight lift on hover */
}

.custom-btn-blue {
    background-color: #007bff; /* Primary color */
    color: #ffffff;
}

.custom-btn-blue:hover {
    background-color: #0056b3; /* Darker shade on hover */    
}

.custom-btn-green {
    background-color: #00801a; /* Primary color */
    color: #ffffff;
}

.custom-btn-green:hover {
    background-color: #004e10; /* Darker shade on hover */
}

.custom-btn-yellow {
    background-color: #f2da02; /* Primary color */
    color: #ffffff;
}

.custom-btn-yellow:hover {
    background-color: #ae9d00; /* Darker shade on hover */
}

.custom-btn-grey {
    background-color: #3e3e3e; /* Primary color */
    color: #ffffff;
}

.custom-btn-grey:hover {
    background-color: #222222; /* Darker shade on hover */
}
.news-img {
  max-width:120px;
  width:auto;
  height:auto;
}


.UserDetails {
  margin: 5px;
  padding: 3px;
  cursor: pointer;
}

.user-details-user-div {
  background-color: #d8e4ed;
}

.user-details-username-div {
  font-weight: 500;
}

.user-details-usertype-div {
  font-weight: 400;
}

.user-details-useractivity-div {
  font-weight: 300;
  font-size: 12px;
}

.user-details-misc-div {
  background: -webkit-gradient(linear, left top, left bottom, from(#ececec), to(#ececec));
  background: linear-gradient(#ececec, #ececec);
}

.user-details-popup-key {
  font-weight: 500;
  margin: 0px 5px 0px 0px;
}

.user-details-popup-val {

}

.user-details-popup-modalbody {
  text-align: center;
  justify-content: center;
}

.SearchHeader {
  margin: 5px;
}

.search-header-btn {
  margin: 5px;
}

.search-header-page-input {
  margin: 5px;
  max-width: 60px;
  text-align: center;
}

.search-header-max-page {
  margin: 5px;
}

.search-header-total-count {
  margin: 5px 5px 5px 25px;
}

.ReplayDetails {
  margin: 10px;
}

.replay-details-table {
  width: 100%;
}

.replay-details-header {
  background: lightgrey;
}

.replay-details-id {
  font-size: 10px;
}

.replay-details-title {
  font-size: 12px;
  width: 100%;
  font-weight: 500;
  padding-left: 20px;
}

.replay-details-header {
  padding: 5px;
}

.replay-details-teams-div {
  display: flex;
  text-align: center;
  padding-top: 20px;
}

.replay-details-faction-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.replay-details-faction-div:hover {
  cursor: pointer;
}

.replay-details-faction-icon {
  width: 25px;
  height: 25px;
  margin: auto;
}

.replay-details-player-name {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.replay-details-uploader-td {
  width: 33%;
}

.replay-details-uploader-div {
  text-align: right;
  margin: 5px;
}

.replay-details-txt {
  font-size: 13px;
}

.replay-details-name {
  font-size: 12px;
  font-weight: 500;
}

.replay-details-uploader-txt {
  font-size: 9px;
}

.replay-details-uploader-name {
  font-size: 10px;
  font-weight: 500;
}

.replay-details-map-div {
  align-self: center;
  margin-left: 10px;
}

.replay-details-map-name {
  font-size: 14px;
}

.replay-details-patch-name {
  font-size: 12px;
  font-weight: 300;
  margin-top: 5px;
  padding-right: 3px;
  padding-left: 3px;
  background-color: lightgrey;
  text-align: center;
}

.replay-details-mappack-name {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.replay-details-minimap {
  width: 75px;
  height: 75px;
  margin: 5px;
  margin-left: 15px;
}

.replay-details-minimap-rev {
  width: 75px;
  height: 75px;
  margin: 5px;
  margin-left: 15px;

  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.replay-details-team {
  width: 50%;
  background-color: #EEEEEE;
  margin: 3px;
  padding: 5px;
}

.replay-details-avatar {
  height: 25px;
  width: 25px;
}

.replay-details-action-div {
 display: flex;
 justify-content: right;
}

.replay-details-action-btn {
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  min-width: 120px;
  text-align: center;
}

.match-results-txt {
  font-size: 14px;  
  text-align: center;
  margin-right: 5px;
  font-weight: 500;
}

.match-result-item {
  display: block;  
  text-align: center;
  padding: 5px;
  margin: 2px;
  background: lightgray;
}

.match-result-winner {
  margin-left: 3px;
  color: green;
}

.match-result-loser {
  margin-left: 3px;
  color: red;
}

.match-result-na {
  margin-left: 3px;
  color: grey;
}

.myImg {
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    width: 300px;
    height: 200px;
    margin: 5px;
}

.myImg:hover {opacity: 0.7;}

.faq-title {
    font-size: 32px;    
    text-decoration: underline;
}

.faq-subtitle {
    font-size: 24px;    
    text-decoration: underline;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: scroll; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

.modal-arrows {
  margin: auto;
  display: block;
  width: 10%;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)}
    to {-webkit-transform:scale(1)}
}

@keyframes zoom {
    from {-webkit-transform:scale(0);transform:scale(0)}
    to {-webkit-transform:scale(1);transform:scale(1)}
}

/* The Close Button */
.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
    .modal-content {
        width: 100%;
    }
    img {
      width: 100%;
    }
}

.carousel-item {
  max-width: 100%;
  background: black;
}

.ladders-content-div {
    grid-gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.LadderDetails {
  margin: 10px;
  padding: 3px;
  cursor: pointer;
  background: #d8e4ed;

  display: flex;
  align-items: flex-end;
}

.ladder-details-num-div {
  position: relative;
  margin-bottom: 10px;
}

.ladder-details-num {
  font-size: 20px;
}

.ladder-details-pts {
  font-size: 14px;
  font-weight: 500;
}

.ladder-details-avatar-img {
  max-width: 30px;
  margin-right: 5px;
}

.ladder-details-username-div {
  font-weight: 500;
}

.ladder-details-faction-img {
  max-width: 25px;
}

@media screen and (max-width: 780px){
  .LadderDetails {
    margin: 5px;
    padding: 3px;
    cursor: pointer;
    background-color: #d8e4ed;
    justify-items: center;
    justify-content: center;
    display: block;
  }

  .LadderDetails > div {
    margin-bottom: 10px;
  }

  .LadderDetails > div > img {
    display: block;
    align-self: center;
    align-content: center;
    margin: auto;
  }

  .LadderDetails > div > div, span {
    display: block;
    text-align: center;
    margin: auto;
  }

}


.convo-user-div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EEEEEE;
    padding: 10px;
}

.convo-user-avatar-img {
    max-width: 45px;
    display: block;
    margin-left: auto;
    margin-right: auto;   
  }

.convo-user-name-div {
    font-size: 15px;
    text-align: center;
}

.convo-body-div {
}

.convo-content-div {
    justify-content: left;
}

.convo-message-div {
    text-align: left;
    display: inline;
}

.convo-btn {
    margin-top: 5px;
    margin-left: 10px;
}
  

@media screen and (max-width: 780px){
    .convo-user-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .convo-message-div {
        text-align: center;
        display: block;
    }

    .convo-btn-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .convo-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.Tournament {
  max-width: 600px;
}

.img-tournament-banner {
  max-width:120px;
  display: block;
  margin: auto;
  width: 40%;
  height:auto;
}

.div-tournament-players {
  padding: 5px;
  display: block;
  text-align: center;
  margin: 10px;
  background-color: lightgrey;
  border-radius: 10px;
}

.div-tournament-summary {
  text-align: left;
}

.tournament-status-inactive {
  width: 100px;
  text-align: center;
  background-color: grey;
  color: white;
  padding: 2px;
}

.tournament-status-active {
  width: 100px; 
  text-align: center;
  background-color: orange;
  color: white;
  padding: 2px;
}

.tournament-status-playing {
  width: 100px;
  text-align: center;
  background-color: green;
  color: white;
  padding: 2px;
}

.tournament-status-cancelled {
  width: 100px;
  text-align: center;
  background-color: red;
  color: white;
  padding: 2px;
}

.tournament-status-completed {
  width: 100px;
  text-align: center;
  background-color: darkgreen;
  color: white;
  padding: 2px;
}
.TournamentDetails {
    position:relative;
    z-index: 1;
    min-height: 1000px;
    overflow-x: hidden;
}

.div-tournament-id {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    background-color: #999;
}

.txt-tournament-id {
    text-align: center;
    text-decoration: underline;
}

.div-tournament-title {
    background-color: black;
}

.title-tournament {
    text-align: center;
    font-size: 24px;
    color: white;
    display: block;
}

.summary-tournament {
    text-align: center;
    font-size: 14px;
    color: white;
    display: block;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
.background-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.div-brackets-container {
    display: block;
    overflow-x: auto;
}

.div-title-row {
    position: relative;
}

.tournament-btns {
    display: flex;
    justify-content: center;
}

.btn-checkin {
    margin: 5px;
}

.btn-signup {
    margin: 5px;
}

.div-players-list {
    background-color: black;
    padding: 5px;
    overflow-x: auto;
    max-width: 100%;
}

.div-players-header {
    color: lightgray;
    text-decoration: underline;
}

.div-players-body {
    font-size: 13px;
}

.icon-player {
    width: 12px;
}

.div-players-body > div:nth-child(even) {
    background-color: rgb(43, 43, 43); 
}

.div-tournament-date {
    display: inline-block;
    text-align: center;
    margin: 5px;
    color: white;
    border-radius: 5px;
    background-color: darkgray;
    padding: 5px;    
}

.txt-tournament-date {
    font-size: 18px;
}

.div-tournament-countdown {
    text-align: center;
    margin: 5px;
    color: white;
    border-radius: 5px;
    background-color: darkgray;
    display: inline-block;
    padding: 5px;    
}

.txt-tournament-countdown {
    font-size: 24px;
    min-width: 200px;
}

.div-main-rows {
    margin-top: 70px;
    position: relative;
}

.div-round-title {
    display: block;
    width: 150px;
    height: 60px;
}

.div-round-title-name {
    display: block;
    color: white;
    border-color: grey;
    border-width: thin;
    border-style: solid;
    background-color: darkblue;
    text-align: center;
    height: 30px;
    width: 150px;
    line-height: 30px;
}

.div-round-title-desc-container {
    display: block;
}

.div-round-title-bo {
    text-align: center;
    height: 20px;
    width: 150px;
    font-weight: 500;
    line-height: 20px;
}

.div-round-title-maps {
    text-align: center;
    height: 30px;
    width: 150px;
    line-height: 15px;
}

.div-team {
    display: block;
    text-align: center;
    background: lightblue;
    border-color: grey;
    border-width: thin;
    border-style: solid;
    height: 30px;
    width: 120px;
    line-height: 30px;
    padding: 0px 2px 0px 2px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.div-team-score {
    display: block;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .txt-tournament-countdown  {
        font-size: 12px;
        min-width: 110px;
    }

    .txt-tournament-date {
        font-size: 11px;
    }

    .div-players-body {
        font-size: 11px;
    }

    .div-players-header {
        font-size: 11px;
    }
    
}
.stats-sheet-loading-div {
    margin-top: 100px;
    text-align: center;
    width: 100%;
}

.stats-sheet-loading-text {
    display: block;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
}
.stats-details-container-div {
  text-align: center;
  margin: 0px;
  min-height: 200px;
  position: relative;
}

.stats-details-progress-div {
  margin-top: 125px;
}

.stats-details-progress-text {
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}

.stats-details-content-div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.stats-details-header {
  margin: 15px;
  text-align: center;
  background-color: black;
  border: 1px solid black;
  border-radius: 1px;
  color: white;
}

.stats-details-refresh-btn {
  margin: 5px;
  float: left;
  object-fit: cover;
  padding: 5px;
}

.stats-details-header-title {
  width: 90%;
}

.stats-details-header-patch {
  display: inline-block;
  margin: 5px 10px 10px 5px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 15px;
  height: 30px;
  text-align: center;
  background-color: gray;
  border: 1px solid gray;
  border-radius: 1px;
}

.stats-details-header-patch-sticky {
  display: inline-block;
  margin: 5px 10px 10px 5px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 15px;
  height: 30px;
  text-align: center;
  background-color: gray;
  border: 1px solid gray;
  border-radius: 1px;
}

.stats-details-header-sticky-left {
  position: fixed;  
  top: -15px;
  left: 0;
  right: 50%;
  z-index: 1000;
  opacity: 0.9;
  background-color: white;
  color: black;
  -webkit-animation: 2500ms ease-in-out 2s normal none 1 running fadeInDown;
          animation: 2500ms ease-in-out 2s normal none 1 running fadeInDown;
}

.stats-details-header-sticky-right {
  position: fixed;
  top: -15px;
  left: 50%;
  right: 0;
  z-index: 1000;
  opacity: 0.9;
  background-color: white;
  color: black;
  -webkit-animation: 2500ms ease-in-out 2s normal none 1 running fadeInDown;
          animation: 2500ms ease-in-out 2s normal none 1 running fadeInDown;
}

.stats-details-content-sticky {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stats-details-content-sticky img {
  height: 50px;
  width: auto;
}
.stats-details-content-sticky > div {
  padding: 10px;
}

.stats-details-portrait-container {
  position: relative;
  height: 120px;
  width: 100%;
  border-width: 3px;
  border-color: white;
}

.stats-details-portrait-input {
  position: absolute;
  top: 50%;
  left: 40%;
}

.stats-details-portrait {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.stats-details-portrait-sticky {
  height: 50px;
  width: auto;
}

.stats-details-container-div ul {
  list-style: none;  
}

.stats-details-subtitle {
  font-size: 20px;
  text-decoration: underline;  
}

.stats-details-subtitle-minor {
  font-size: 18px;
  font-style: italic; 
}

.stats-details-note {
  font-size: 11px;
  font-style: italic;
}

.stats-details-value-string {
  display: block;
  margin: 0px;
  padding: 0px;
}

.stats-details-value-numeric {
  display: block;
  margin: 0px;
  padding: 0px;
}

.stats-details-value {
  text-align: left;
  word-break: break-all;
  background: transparent;
  border: none;
  resize: none;
  word-wrap: normal;
  overflow: hidden;
}

.stats-details-small {
  font-size: 11px;
  font-style: italic;
}

.stats-details-mult {  
  display: inline;
  text-decoration: underline;
  float: right;
  padding-right: 5px;
  font-size: 11px;
  text-align: left;
}

.stats-details-container-div .stats-details-section {
  background-color: black; /* Default color for level 1 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 2 */
  color: black;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section {
  background-color: black; /* Default color for level 3 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 4 */
  color: black;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: black; /* Default color for level 5 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 5 */
  color: black;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: black; /* Default color for level 5 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 5 */ 
  color: black;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: black; /* Default color for level 5 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 5 */
  color: black;
}

.stats-details-section {
  margin: 5px 0px 5px 1px;
  padding: 5px 0px 5px 5px;
  border-radius: 1px;  
}

.stats-details-section-list {
  margin: 5px 0px 5px 1px;
  padding: 5px 0px 5px 5px;
  border-radius: 1px;  
}

.stats-details-list-item {
  margin: 5px 10px 5px 10px;
  padding: 5px;
  background-color: lightgray;
  border-radius: 1px;  
}

.stats-details-list-subitem {
  margin: 5px 10px 5px 10px;
  padding: 5px;
  background-color: lightsteelblue;
  border-radius: 1px;  
}

.stats-details-list-specialitem {
  margin: 5px 10px 5px 10px;
  padding: 5px;
  background-color: lightgoldenrodyellow;
  border-radius: 1px;  
}

.stats-details-desc {
  margin: 25px 5px 5px 5px;
  padding: 10px;
  background-color: black;
  color: white;
  border-radius: 1px;
  white-space: pre-wrap;
}

.btn-unit-details-save {
  margin: 25px 5px 5px 5px;
}

.btn-stats-details-tree {
  display: block;
  text-align: center;
  margin: 10px;
}
.tree-faction-div {
  margin: 10px;
  text-align: center;
}

.tree-faction-title {
  text-align: center;
  font-size: 20px;
  text-decoration: underline;
}

.tree-category-title {
  margin-top: 15px;
  font-style: italic;
  font-size: 15px;
}

.tree-units-container {
  margin: 0px 0px 5px 0px;
  padding: 5px;
  background-color: lightsteelblue;
  border: 1px solid lightsteelblue;
  border-radius: 10px;  
}

.tree-unit-row-div {
  margin-top: 10px;
}

.tree-unit-div {
  cursor: pointer;
}

.tree-faction-unit-name {
  text-align: center;
}

.tree-unit-img {
  max-height: 40px;
  object-fit: contain;
}

.donations-table {
    width: 100%;
}

tr {
    border: 1px solid black;    
    border-collapse: collapse;
}

th, td {
    padding: 10px;
}
.root-nav {
  background-color: #e1e7f2;
}

.root-nav-link {
  background: #e1e7f2;
  min-width: 150px;
  text-align: center;
  padding: 5px;
}

.root-nav-link:hover {
  background-color: #cedbef;
}

.btn-login {
}

.btn-login:hover {
  cursor: pointer;
}

.btn-logout {
  margin: 5px;
  float: right;
}

.btn-logout:hover {
  cursor: pointer;
}

.root-div-profile {
  align-self: center;
  display: flex;
}

.root-img-profile {
  width: 20px;
  height: 20px;
}

.img-pm {
  width: 30px;
  height: 30px;
}

.div-discord {
  background-color: #ffd145;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  display: inline;
}

.div-push-error {

}

.div-pm {
  display: inline-block;
}

.div-pm:hover {
  cursor: pointer;
}

.root-div-profile-name {
  margin-left: 5px;  
  max-width: 125px;
  word-wrap: break-word;
  font-size: 13px;
  text-align: right;
}

@media only screen and (min-width: 450px) {
  .root-div-profile-name {
    margin-left: 5px;
    max-width: 125px;
    word-wrap: break-word;
    font-size: 13px;
    text-align: center;
  }
}

.root-login-div {
  text-align: right;
}

@media only screen and (min-width: 450px) {
  .root-login-div {
    text-align: right;
  }
}

.cp-banner-div {
  display: block;
  background-color: lightblue;
  padding: 5px 5px 5px 10px;
  color: white;
}

.cp-info-div {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.subscribe-txt-div {
  padding: 10px;
}

.subscribe-btn-div {
  font-size: 15px;
  text-align: center;
}

.subscribe-div {
  background-color: #ffd145 ;
  padding: 10px;
}

.donate-div {
  background-color: #edf3ff;
  padding: 10px;
}

.donate-btn-div {
  color: white;
  color: lightblue;
  font-size: 13px;
  text-align: center;
}

.donate-span {
  color: black;
  margin: 5px;
  font-size: 13px;
  text-align: center;
}

.donate-prog-div {
  text-align: center;
  font-size: 14px;
  padding: 10px 10px 5px 15px;
  width: 100%;
}

.generic-link {
  margin-left: 10px;
  cursor:pointer;
  color:#0a8afd;
  text-decoration:none;
}

.generic-link:hover {
  text-decoration:underline;
  color:#005dbd;
}
.error-div {
  margin: 30px;
  font-size: 16px;
  text-align: center;
}

.error-img {
  width: 40%;
  object-fit: contain;
  margin: 20px;
}
.thankyou-div {
  margin: 30px;
  font-size: 24px;
  text-align: center;
}

.thankyou-content-div {
  width: 100%;
}

.thankyou-img {
  width: 25%;
  margin: 10px;
  display: block;
  margin: auto;
}

.thankyou-txt {
  margin: 15px 0px 0px 0px ;
  font-size: 18px;
}

@media only screen and (min-width: 800px) {
  .thankyou-img {
    margin: 10px;
    width: 25%;
    float: left;
  }
}

@media only screen and (min-width: 800px) {
  .thankyou-txt {
    width: 75%;
    margin: 25px 0px 0px 0px ;
    font-size: 18px;
  }
}
 
.PrivacyPolicy {
    
}
.Footer {
    margin-top: 0px;
    padding: 10px;
    background-color: lightgray;    
}

.footer-content-right {
    display: inline-block;
    text-align: right;
}

.footer-content-left {
    display: inline-block;
    text-align: left;
}

.version-text {
    display: inline-block;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    background: cornsilk;
}
.subscribe-main-div {
    padding: 25px;
    /*text-align: center;*/
    background-color: rgb(232, 232, 232);
}

.subscribe-main-div h2 {
    text-align: center;
}

.subscribe-intro-div {
    /*text-align: center;*/
    padding: 15px;
    font-size: 17px;
}

.subscribe-intro-div ul {
    display: inline-block;
    font-size: 16px;    
    width: 100%;
    text-align: left;
}

.subscribe-types-div {
    text-align: center;
    padding: 3px;
}

.subscribe-card-div {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;   
    padding: 10px;    
    background-color: ghostwhite;
}

.subscribe-card-div:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.subscribe-types-div ul {
    display: inline-block;
    width: 60%;
    text-align: left;
}

.subscribe-custom-div {
    margin: 25px 5px 5px 5px;
    text-align: center;
}

.subscribe-icon {
    margin-right: 10px;
    margin-bottom: 6px;
    max-height: 24px;
    width: auto;
    height: auto;
    background-color: orange;
    border-radius: 5px;
}

.paypal-icon {
    margin-left: 10px;
    max-height: 24px;
    width: auto;
    height: auto;
}
