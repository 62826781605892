.tree-faction-div {
  margin: 10px;
  text-align: center;
}

.tree-faction-title {
  text-align: center;
  font-size: 20px;
  text-decoration: underline;
}

.tree-category-title {
  margin-top: 15px;
  font-style: italic;
  font-size: 15px;
}

.tree-units-container {
  margin: 0px 0px 5px 0px;
  padding: 5px;
  background-color: lightsteelblue;
  border: 1px solid lightsteelblue;
  border-radius: 10px;  
}

.tree-unit-row-div {
  margin-top: 10px;
}

.tree-unit-div {
  cursor: pointer;
}

.tree-faction-unit-name {
  text-align: center;
}

.tree-unit-img {
  max-height: 40px;
  object-fit: contain;
}
