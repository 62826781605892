.stats-details-container-div {
  text-align: center;
  margin: 0px;
  min-height: 200px;
  position: relative;
}

.stats-details-progress-div {
  margin-top: 125px;
}

.stats-details-progress-text {
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}

.stats-details-content-div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.stats-details-header {
  margin: 15px;
  text-align: center;
  background-color: black;
  border: 1px solid black;
  border-radius: 1px;
  color: white;
}

.stats-details-refresh-btn {
  margin: 5px;
  float: left;
  object-fit: cover;
  padding: 5px;
}

.stats-details-header-title {
  width: 90%;
}

.stats-details-header-patch {
  display: inline-block;
  margin: 5px 10px 10px 5px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 15px;
  height: 30px;
  text-align: center;
  background-color: gray;
  border: 1px solid gray;
  border-radius: 1px;
}

.stats-details-header-patch-sticky {
  display: inline-block;
  margin: 5px 10px 10px 5px;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 15px;
  height: 30px;
  text-align: center;
  background-color: gray;
  border: 1px solid gray;
  border-radius: 1px;
}

.stats-details-header-sticky-left {
  position: fixed;  
  top: -15px;
  left: 0;
  right: 50%;
  z-index: 1000;
  opacity: 0.9;
  background-color: white;
  color: black;
  animation: 2500ms ease-in-out 2s normal none 1 running fadeInDown;
}

.stats-details-header-sticky-right {
  position: fixed;
  top: -15px;
  left: 50%;
  right: 0;
  z-index: 1000;
  opacity: 0.9;
  background-color: white;
  color: black;
  animation: 2500ms ease-in-out 2s normal none 1 running fadeInDown;
}

.stats-details-content-sticky {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stats-details-content-sticky img {
  height: 50px;
  width: auto;
}
.stats-details-content-sticky > div {
  padding: 10px;
}

.stats-details-portrait-container {
  position: relative;
  height: 120px;
  width: 100%;
  border-width: 3px;
  border-color: white;
}

.stats-details-portrait-input {
  position: absolute;
  top: 50%;
  left: 40%;
}

.stats-details-portrait {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.stats-details-portrait-sticky {
  height: 50px;
  width: auto;
}

.stats-details-container-div ul {
  list-style: none;  
}

.stats-details-subtitle {
  font-size: 20px;
  text-decoration: underline;  
}

.stats-details-subtitle-minor {
  font-size: 18px;
  font-style: italic; 
}

.stats-details-note {
  font-size: 11px;
  font-style: italic;
}

.stats-details-value-string {
  display: block;
  margin: 0px;
  padding: 0px;
}

.stats-details-value-numeric {
  display: block;
  margin: 0px;
  padding: 0px;
}

.stats-details-value {
  text-align: left;
  word-break: break-all;
  background: transparent;
  border: none;
  resize: none;
  word-wrap: normal;
  overflow: hidden;
}

.stats-details-small {
  font-size: 11px;
  font-style: italic;
}

.stats-details-mult {  
  display: inline;
  text-decoration: underline;
  float: right;
  padding-right: 5px;
  font-size: 11px;
  text-align: left;
}

.stats-details-container-div .stats-details-section {
  background-color: black; /* Default color for level 1 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 2 */
  color: black;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section {
  background-color: black; /* Default color for level 3 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 4 */
  color: black;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: black; /* Default color for level 5 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 5 */
  color: black;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: black; /* Default color for level 5 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 5 */ 
  color: black;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: black; /* Default color for level 5 */
  color: white;
}
.stats-details-container-div .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section .stats-details-section {
  background-color: lightgray; /* Default color for level 5 */
  color: black;
}

.stats-details-section {
  margin: 5px 0px 5px 1px;
  padding: 5px 0px 5px 5px;
  border-radius: 1px;  
}

.stats-details-section-list {
  margin: 5px 0px 5px 1px;
  padding: 5px 0px 5px 5px;
  border-radius: 1px;  
}

.stats-details-list-item {
  margin: 5px 10px 5px 10px;
  padding: 5px;
  background-color: lightgray;
  border-radius: 1px;  
}

.stats-details-list-subitem {
  margin: 5px 10px 5px 10px;
  padding: 5px;
  background-color: lightsteelblue;
  border-radius: 1px;  
}

.stats-details-list-specialitem {
  margin: 5px 10px 5px 10px;
  padding: 5px;
  background-color: lightgoldenrodyellow;
  border-radius: 1px;  
}

.stats-details-desc {
  margin: 25px 5px 5px 5px;
  padding: 10px;
  background-color: black;
  color: white;
  border-radius: 1px;
  white-space: pre-wrap;
}

.btn-unit-details-save {
  margin: 25px 5px 5px 5px;
}

.btn-stats-details-tree {
  display: block;
  text-align: center;
  margin: 10px;
}