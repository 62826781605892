.convo-user-div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EEEEEE;
    padding: 10px;
}

.convo-user-avatar-img {
    max-width: 45px;
    display: block;
    margin-left: auto;
    margin-right: auto;   
  }

.convo-user-name-div {
    font-size: 15px;
    text-align: center;
}

.convo-body-div {
}

.convo-content-div {
    justify-content: left;
}

.convo-message-div {
    text-align: left;
    display: inline;
}

.convo-btn {
    margin-top: 5px;
    margin-left: 10px;
}
  

@media screen and (max-width: 780px){
    .convo-user-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .convo-message-div {
        text-align: center;
        display: block;
    }

    .convo-btn-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .convo-btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
