.donations-table {
    width: 100%;
}

tr {
    border: 1px solid black;    
    border-collapse: collapse;
}

th, td {
    padding: 10px;
}