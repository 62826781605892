.Tournament {
  max-width: 600px;
}

.img-tournament-banner {
  max-width:120px;
  display: block;
  margin: auto;
  width: 40%;
  height:auto;
}

.div-tournament-players {
  padding: 5px;
  display: block;
  text-align: center;
  margin: 10px;
  background-color: lightgrey;
  border-radius: 10px;
}

.div-tournament-summary {
  text-align: left;
}

.tournament-status-inactive {
  width: 100px;
  text-align: center;
  background-color: grey;
  color: white;
  padding: 2px;
}

.tournament-status-active {
  width: 100px; 
  text-align: center;
  background-color: orange;
  color: white;
  padding: 2px;
}

.tournament-status-playing {
  width: 100px;
  text-align: center;
  background-color: green;
  color: white;
  padding: 2px;
}

.tournament-status-cancelled {
  width: 100px;
  text-align: center;
  background-color: red;
  color: white;
  padding: 2px;
}

.tournament-status-completed {
  width: 100px;
  text-align: center;
  background-color: darkgreen;
  color: white;
  padding: 2px;
}