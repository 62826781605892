.SearchHeader {
  margin: 5px;
}

.search-header-btn {
  margin: 5px;
}

.search-header-page-input {
  margin: 5px;
  max-width: 60px;
  text-align: center;
}

.search-header-max-page {
  margin: 5px;
}

.search-header-total-count {
  margin: 5px 5px 5px 25px;
}
