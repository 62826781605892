/*
.custom-btn {
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

@media (max-width: 768px) {
    .custom-btn {
        padding: 5px 10px;
        font-size: 9px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
      }
}

.custom-btn > img {
    width: 15px;
}
*/
  
.custom-btn {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    gap: 8px; /* Add spacing between icon and text */
    padding: 5px 10px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    text-align: center; /* Ensure proper text alignment */
}

.custom-btn > img {
    width: 15px;
    margin-right: 5px;
}

.custom-btn > span {
    white-space: nowrap; /* Prevent text wrapping */
}

/* Styles for smaller screens */
@media (max-width: 768px) {
    .custom-btn {
        flex-direction: column; /* Stack items vertically */
        gap: 4px; /* Reduce gap between icon and text */
        font-size: 10px; /* Adjust font size */
        padding: 8px; /* Adjust padding for smaller buttons */
    }

    .custom-btn > img {
        margin-right: 5px;
    }
}

.custom-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300%;
    height: 300%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.6), transparent);
    transition: all 0.3s ease;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.1);
}

.custom-btn:hover::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.custom-btn:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px); /* Slight lift on hover */
}

.custom-btn-blue {
    background-color: #007bff; /* Primary color */
    color: #ffffff;
}

.custom-btn-blue:hover {
    background-color: #0056b3; /* Darker shade on hover */    
}

.custom-btn-green {
    background-color: #00801a; /* Primary color */
    color: #ffffff;
}

.custom-btn-green:hover {
    background-color: #004e10; /* Darker shade on hover */
}

.custom-btn-yellow {
    background-color: #f2da02; /* Primary color */
    color: #ffffff;
}

.custom-btn-yellow:hover {
    background-color: #ae9d00; /* Darker shade on hover */
}

.custom-btn-grey {
    background-color: #3e3e3e; /* Primary color */
    color: #ffffff;
}

.custom-btn-grey:hover {
    background-color: #222222; /* Darker shade on hover */
}