.UserDetails {
  margin: 5px;
  padding: 3px;
  cursor: pointer;
}

.user-details-user-div {
  background-color: #d8e4ed;
}

.user-details-username-div {
  font-weight: 500;
}

.user-details-usertype-div {
  font-weight: 400;
}

.user-details-useractivity-div {
  font-weight: 300;
  font-size: 12px;
}

.user-details-misc-div {
  background: linear-gradient(#ececec, #ececec);
}
