.root-nav {
  background-color: #e1e7f2;
}

.root-nav-link {
  background: #e1e7f2;
  min-width: 150px;
  text-align: center;
  padding: 5px;
}

.root-nav-link:hover {
  background-color: #cedbef;
}

.btn-login {
}

.btn-login:hover {
  cursor: pointer;
}

.btn-logout {
  margin: 5px;
  float: right;
}

.btn-logout:hover {
  cursor: pointer;
}

.root-div-profile {
  align-self: center;
  display: flex;
}

.root-img-profile {
  width: 20px;
  height: 20px;
}

.img-pm {
  width: 30px;
  height: 30px;
}

.div-discord {
  background-color: #ffd145;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  display: inline;
}

.div-push-error {

}

.div-pm {
  display: inline-block;
}

.div-pm:hover {
  cursor: pointer;
}

.root-div-profile-name {
  margin-left: 5px;  
  max-width: 125px;
  word-wrap: break-word;
  font-size: 13px;
  text-align: right;
}

@media only screen and (min-width: 450px) {
  .root-div-profile-name {
    margin-left: 5px;
    max-width: 125px;
    word-wrap: break-word;
    font-size: 13px;
    text-align: center;
  }
}

.root-login-div {
  text-align: right;
}

@media only screen and (min-width: 450px) {
  .root-login-div {
    text-align: right;
  }
}

.cp-banner-div {
  display: block;
  background-color: lightblue;
  padding: 5px 5px 5px 10px;
  color: white;
}

.cp-info-div {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.subscribe-txt-div {
  padding: 10px;
}

.subscribe-btn-div {
  font-size: 15px;
  text-align: center;
}

.subscribe-div {
  background-color: #ffd145 ;
  padding: 10px;
}

.donate-div {
  background-color: #edf3ff;
  padding: 10px;
}

.donate-btn-div {
  color: white;
  color: lightblue;
  font-size: 13px;
  text-align: center;
}

.donate-span {
  color: black;
  margin: 5px;
  font-size: 13px;
  text-align: center;
}

.donate-prog-div {
  text-align: center;
  font-size: 14px;
  padding: 10px 10px 5px 15px;
  width: 100%;
}

.generic-link {
  margin-left: 10px;
  cursor:pointer;
  color:#0a8afd;
  text-decoration:none;
}

.generic-link:hover {
  text-decoration:underline;
  color:#005dbd;
}