.subscribe-main-div {
    padding: 25px;
    /*text-align: center;*/
    background-color: rgb(232, 232, 232);
}

.subscribe-main-div h2 {
    text-align: center;
}

.subscribe-intro-div {
    /*text-align: center;*/
    padding: 15px;
    font-size: 17px;
}

.subscribe-intro-div ul {
    display: inline-block;
    font-size: 16px;    
    width: 100%;
    text-align: left;
}

.subscribe-types-div {
    text-align: center;
    padding: 3px;
}

.subscribe-card-div {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;   
    padding: 10px;    
    background-color: ghostwhite;
}

.subscribe-card-div:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.subscribe-types-div ul {
    display: inline-block;
    width: 60%;
    text-align: left;
}

.subscribe-custom-div {
    margin: 25px 5px 5px 5px;
    text-align: center;
}

.subscribe-icon {
    margin-right: 10px;
    margin-bottom: 6px;
    max-height: 24px;
    width: auto;
    height: auto;
    background-color: orange;
    border-radius: 5px;
}

.paypal-icon {
    margin-left: 10px;
    max-height: 24px;
    width: auto;
    height: auto;
}