.file-details-main {
  margin: 10px;
  padding: 10px;
  min-height: 175px;
  background-color: #d8e4ed;
}

.file-details-name {
  font-size: 18px;
  margin-bottom: 3px;

}

.file-details-banner {
  width: 150px;
}

.file-type-title {
  font-size: 16px;
}