.user-details-popup-key {
  font-weight: 500;
  margin: 0px 5px 0px 0px;
}

.user-details-popup-val {

}

.user-details-popup-modalbody {
  text-align: center;
  justify-content: center;
}
