.LadderDetails {
  margin: 10px;
  padding: 3px;
  cursor: pointer;
  background: #d8e4ed;

  display: flex;
  align-items: flex-end;
}

.ladder-details-num-div {
  position: relative;
  margin-bottom: 10px;
}

.ladder-details-num {
  font-size: 20px;
}

.ladder-details-pts {
  font-size: 14px;
  font-weight: 500;
}

.ladder-details-avatar-img {
  max-width: 30px;
  margin-right: 5px;
}

.ladder-details-username-div {
  font-weight: 500;
}

.ladder-details-faction-img {
  max-width: 25px;
}

@media screen and (max-width: 780px){
  .LadderDetails {
    margin: 5px;
    padding: 3px;
    cursor: pointer;
    background-color: #d8e4ed;
    justify-items: center;
    justify-content: center;
    display: block;
  }

  .LadderDetails > div {
    margin-bottom: 10px;
  }

  .LadderDetails > div > img {
    display: block;
    align-self: center;
    align-content: center;
    margin: auto;
  }

  .LadderDetails > div > div, span {
    display: block;
    text-align: center;
    margin: auto;
  }

}

