.Footer {
    margin-top: 0px;
    padding: 10px;
    background-color: lightgray;    
}

.footer-content-right {
    display: inline-block;
    text-align: right;
}

.footer-content-left {
    display: inline-block;
    text-align: left;
}

.version-text {
    display: inline-block;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    background: cornsilk;
}